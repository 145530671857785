<template>
    <div class="card" style="max-width: 600px;">
      <img src="@/assets/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" @click="$emit('close')" />
      <div class="card-header">
        <h1>Execution Details</h1>
        <span :class="['execution-type', execution.status.toLowerCase()]">
          {{ execution.status }}
        </span>
      </div>
      <div class="card-body">
        <div class="details-grid">
          <div class="detail-item">
            <label>Start Time</label>
            <div class="detail-value form-control">{{ new Date(execution.startTime).toLocaleString() }}</div>
          </div>
          <div class="detail-item">
            <label>End Time</label>
            <div class="detail-value form-control">{{ new Date(execution.endTime).toLocaleString() }}</div>
          </div>
          <div class="detail-item">
            <label>Duration</label>
            <div class="detail-value form-control">{{ execution.executionTimeMs }}ms</div>
          </div>
          
          <!-- Show response details if successful -->
          <template v-if="execution.status === 'SUCCESS' && execution.response">
            <div class="detail-item full-width">
              <label>Response Status</label>
              <div class="detail-value form-control">{{ execution.response.status }}</div>
            </div>
            <div class="detail-item full-width">
              <label>Response Type</label>
              <div class="detail-value form-control">{{ execution.response.type }}</div>
            </div>
            <div class="detail-item full-width" v-if="execution.response.headers">
              <label>Headers</label>
              <pre class="detail-code form-control">{{ JSON.stringify(execution.response.headers, null, 2) }}</pre>
            </div>
          </template>

          <!-- Show error details if failed -->
          <template v-if="execution.status === 'ERROR' && execution.error">
            <div class="detail-item full-width">
              <label>Error Message</label>
              <div class="detail-value form-control error-text">{{ execution.error.message }}</div>
            </div>
            <div class="detail-item full-width" v-if="execution.error.stack">
              <label>Stack Trace</label>
              <pre class="detail-code form-control">{{ execution.error.stack }}</pre>
            </div>
          </template>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'ExecutionDetailModal',
  props: {
    execution: Object
  }
}
</script>

<style scoped>

.details-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--space-md);
}

.detail-item {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
}

.detail-item.full-width {
  grid-column: 1 / -1;
}

.detail-item label {
  color: var(--text-tertiary);
  font-size: var(--font-size-xs);
}

.detail-value {
  font-size: var(--font-size-sm);
}

.detail-code {
  white-space: pre-wrap;
  word-break: break-all;
}

.error-text {
  color: var(--error-500);
}

.execution-type {
  font-size: var(--font-size-xs);
  padding: var(--space-xs) var(--space-sm);
  background: var(--background-secondary);
  color: var(--text-secondary);
  border-radius: 2px;
  text-transform: lowercase;
}

.execution-type.success {
  background: var(--success-700);
  color: var(--success-100);
}

.execution-type.error {
  background: var(--error-700);
  color: var(--error-100);
}
</style>