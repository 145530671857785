<template>
  <div class="script-toolbar">
    <transition name="slide" mode="out-in">
      <div class="tool-list" v-if="!activeTool">
        <div class="tool-item" @click="openTool('generate')">
          <img src="@/assets/icons/magic.png" alt="Generate">
        </div>
        <div class="tool-item" @click="openTool('edit')">
          <img src="@/assets/icons/edit.png" alt="Edit">
        </div>
        <div class="tool-item" @click="openTool('history')">
          <img src="@/assets/icons/history.png" alt="History">
        </div>
      </div>
      <!-- Generate tool -->
      <div class="tool-pane card" v-else-if="activeTool == 'generate'">
        <div class="card-header">
          <h3>Generate {{ scriptTypeDisplay }}</h3>
          <img src="@/assets/icons/close.png" alt="Close Modal" class="close-modal-icon" @click="closeTool()" />
        </div>
        <div class="tool-pane-body">
          <div class="form-group">
            <textarea type="text" id="function-input" class="form-control" placeholder=" " v-model="description"
              rows="1" @input="handleResize" />
            <label for="function-input" class="form-label">{{ scriptTypeDisplay }} description</label>
          </div>
        </div>
        <div class="card-footer">
          <button class="button" @click="attemptGenerateScript">
            <img v-if="isLoading" src="@/assets/loading.svg" alt="Loading" class="loading-icon">
            <span v-else>Run Model</span>
          </button>
        </div>
      </div>
      <!-- Edit tool -->
      <div class="tool-pane card" v-else-if="activeTool == 'edit'">
        <div class="card-header">
          <h3>Edit {{ scriptTypeDisplay }}</h3>
          <img src="@/assets/icons/close.png" alt="Close Modal" class="close-modal-icon" @click="closeTool()" />
        </div>
        <div class="tool-pane-body">
          <div class="form-group">
            <textarea type="text" id="function-input" class="form-control" placeholder=" " v-model="adjustment" rows="1"
              @input="handleResize" />
            <label for="function-input" class="form-label">Request adjustment</label>
          </div>

          <div v-if="currentSelection.length > 0" class="selection-control">
            <label class="checkbox-wrapper">
              <input type="checkbox" v-model="useSelection" class="checkbox-input">
              <span class="checkbox-custom"></span>
              <span class="checkbox-label">Only modify current selection ({{ currentSelection.length }}
                characters)</span>
            </label>
          </div>
        </div>
        <div class="card-footer">
          <button class="button" @click="attemptAdjustScript">
            <img v-if="isLoading" src="@/assets/loading.svg" alt="Loading" class="loading-icon">
            <span v-else>Run Model</span>
          </button>
        </div>
      </div>
      <!-- History tool -->
      <div class="tool-pane card" v-else-if="activeTool == 'history'">
        <div class="card-header">
          <h3>Script History</h3>
          <img src="@/assets/icons/close.png" alt="Close Modal" class="close-modal-icon" @click="closeTool()" />
        </div>
        <div class="tool-pane-body">
          <div class="history-list">
            <!-- Head/Current Version item -->
            <div class="history-item" :class="{ 'history-item-active': activeChangeId == '' }"
              @click="$emit('onLoadHistoryItem', null)">
              <div class="history-item-header">
                <div class="history-item-info">
                  <span class="history-message">Current Version</span>
                  <span class="history-time">Latest changes</span>
                </div>
                <div class="history-indicators">
                  <span v-if="!activeChangeId" class="history-active-badge">Current</span>
                  <span class="history-type">latest</span>
                </div>
              </div>
            </div>
            <div v-for="item in scriptInfo.history.slice().reverse()" :key="item.id" class="history-item"
              :class="{ 'history-item-active': item.id === activeChangeId }" @click="$emit('onLoadHistoryItem', item)">
              <div class="history-item-header">
                <div class="history-item-info">
                  <span class="history-message">{{ item.message || 'No description provided' }}</span>
                  <span class="history-time">{{ formatDate(item.time) }}</span>
                </div>
                <div class="history-indicators">
                  <span v-if="item.id === activeChangeId" class="history-active-badge">Current</span>
                  <span class="history-type">{{ item.type.toLowerCase() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as scriptApi from '@/api/script.js'

export default {
  name: 'ScriptToolbar',
  props: {
    scriptInfo: Object,
    scriptTypeDisplay: String,
    editorContent: String,
    currentSelection: String,
    activeChangeId: String
  },
  data() {
    return {
      activeTool: '',
      description: '',
      adjustment: '',
      isLoading: false,
      useSelection: false
    }
  },
  methods: {
    async attemptGenerateScript() {
      if (this.isLoading) return;

      try {
        this.isLoading = true;

        const newContent = await scriptApi.aiGenerate({
          scriptId: this.scriptInfo.id,
          description: this.description
        })

        this.$emit('onContentUpdated', newContent);

        this.description = '';
        this.isLoading = false;
        this.closeTool();

      } catch (err) {
        alert('Failed: ' + err.message);
        this.isLoading = false;
      }
    },
    async attemptAdjustScript() {
      if (this.isLoading) return;

      try {
        this.isLoading = true;

        const partialText = this.useSelection && this.currentSelection.length > 0;

        const updatedContent = await scriptApi.aiAdjust({
          scriptId: this.scriptInfo.id,
          adjustment: this.adjustment,
          partialText: partialText ? true : false,
          content: partialText ? this.currentSelection : this.editorContent
        })

        this.$emit('onContentUpdated', updatedContent, partialText);

        this.adjustment = '';
        this.useSelection = false;
        this.isLoading = false;
        this.closeTool();

      } catch (err) {
        alert('Failed: ' + err.message);
        this.isLoading = false;
      }
    },
    openTool(tool) {
      if (this.isLoading) return;
      this.activeTool = tool;
      this.useSelection = this.currentSelection != null && this.currentSelection.length > 0
    },
    closeTool() {
      if (this.isLoading) return;
      this.activeTool = '';
      this.useSelection = false; // Reset selection checkbox when closing tool
    },
    handleResize(event) {
      const target = event.target;
      target.style.height = '1px';
      target.style.height = `${target.scrollHeight}px`
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('default', {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit'
      }).format(date);
    },
  },
  emits: ['onContentUpdated', 'onLoadHistoryItem']
}
</script>

<style scoped>
.script-toolbar {
  position: absolute;
  top: var(--space-sm);
  right: var(--space-sm);
  width: 0;
  height: calc(100% - var(--space-md));
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.tool-list {
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
}

.tool-item {
  padding: var(--space-sm);
  background: var(--background-primary);
  border-radius: 2px;
  border: 1px solid var(--border-primary);
  cursor: pointer;
  transition: var(--transition-base);
}

.tool-item:hover {
  background: var(--background-secondary);
}

.tool-item img {
  width: 30px;
  height: 30px;
  display: block;
}

.tool-pane {
  position: relative;
  flex-shrink: 0;
  width: 500px;
  max-height: 100%;
  background: var(--background-secondary);
  border: 1px solid var(--border-primary);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
}

.tool-pane-header {
  padding: var(--space-md);
  padding-bottom: var(--space-sm);
  border-bottom: 1px solid var(--border-primary);
}

.tool-pane-header h3 {
  color: var(--text-primary);
  font-size: var(--font-size-xl);
}

.tool-pane-footer {
  padding: var(--space-md);
  padding-top: var(--space-sm);
  border-top: 1px solid var(--border-primary);
}

.tool-pane-body {
  height: 1px;
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  margin: -20px;
}

.close-modal-icon {
  position: absolute;
  right: var(--space-md);
  top: var(--space-md);
  width: 16px;
  height: 16px;
  cursor: pointer;
  opacity: 0.5;
  transition: var(--transition-fast);
}

.close-modal-icon:hover {
  opacity: 1;
}

/* Transition styles */
.slide-enter-active,
.slide-leave-active {
  transition: var(--transition-base);
  transform: none;
  opacity: 1;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateX(10px);
}

.selection-control {
  margin-top: var(--space-md);
}

.checkbox-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--space-sm);
  cursor: pointer;
  user-select: none;
}

.checkbox-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-custom {
  position: relative;
  height: 24px;
  width: 24px;
  background: var(--background-primary);
  border: 1px solid var(--border-primary);
  border-radius: 3px;
  transition: var(--transition-fast);
}

.checkbox-label {
  color: var(--text-secondary);
  font-size: var(--font-size-base);
}

/* Checkbox states */
.checkbox-input:checked+.checkbox-custom {
  background: var(--primary-500);
  border-color: var(--primary-500);
}

.checkbox-input:checked+.checkbox-custom::after {
  content: '';
  position: absolute;
  left: 6px;
  top: 2px;
  width: 8px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Hover states */
.checkbox-wrapper:hover .checkbox-custom {
  border-color: var(--primary-400);
}

.checkbox-input:checked:hover+.checkbox-custom {
  background: var(--primary-400);
  border-color: var(--primary-400);
}

/* Focus states */
.checkbox-input:focus+.checkbox-custom {
  outline: 2px solid var(--primary-400);
  outline-offset: 2px;
}

.history-list {
  display: flex;
  flex-direction: column;
  gap: var(--space-md);
  padding: var(--space-sm);
}

.history-item {
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
  padding: var(--space-md);
  background: var(--background-primary);
  border: 1px solid var(--border-primary);
  border-radius: 2px;
  cursor: pointer;
  transition: var(--transition-base);
  outline: none;
}

/* Hover and focus states */
.history-item:hover,
.history-item:focus-visible {
  background: var(--background-secondary);
  border-color: var(--primary-400);
}

/* Active state */
.history-item:active {
  transform: scale(0.995);
}

.history-item-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--space-md);
}

.history-item-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
}

.history-message {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

.history-time {
  color: var(--text-tertiary);
  font-size: var(--font-size-xs);
}

.history-type {
  font-size: var(--font-size-xs);
  padding: var(--space-xs) var(--space-sm);
  background: var(--background-secondary);
  color: var(--text-secondary);
  border-radius: 2px;
  text-transform: lowercase;
}

.history-content {
  margin-top: var(--space-sm);
}

.history-content-wrapper {
  position: relative;
}

.history-code {
  overflow-x: auto;
  padding: var(--space-sm);
  background: var(--background-secondary);
  border-radius: 2px;
  max-height: 160px;
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.history-fade {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 32px;
  background: linear-gradient(to top, var(--background-secondary), transparent);
  pointer-events: none;
}

.history-indicators {
  display: flex;
  gap: var(--space-xs);
  align-items: center;
}

.history-active-badge {
  font-size: var(--font-size-xs);
  padding: var(--space-xs) var(--space-sm);
  background: var(--primary-500);
  color: var(--text-primary);
  border-radius: 2px;
  text-transform: lowercase;
}

.history-item-active {
  background: var(--background-secondary);
  border-color: var(--primary-500);
}

/* Modify hover state for active item */
.history-item-active:hover {
  border-color: var(--primary-400);
  background: color-mix(in srgb, var(--background-secondary) 95%, var(--primary-500) 5%);
}

/* Modify the existing history-type style to align with new layout */
.history-type {
  font-size: var(--font-size-xs);
  padding: var(--space-xs) var(--space-sm);
  background: var(--background-secondary);
  color: var(--text-secondary);
  border-radius: 2px;
  text-transform: lowercase;
}
</style>