import { postJson } from "./common"

export async function invokeFunctionJson(id, payload) {
  return await fetch(`${process.env.VUE_APP_API}/function/invoke/${id}`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload)
  });
}

export async function invokeFunctionForm(id, body) {
  return await fetch(`${process.env.VUE_APP_API}/function/invoke/${id}`, {
    credentials: 'include',
    method: 'POST',
    body: body
  });
}

export async function listExecutions({ scriptId }) {
  const resJson = await postJson('/function/list-executions', { scriptId });
  if (!resJson.executions) throw Error('Malformed response from backend');
  return resJson.executions;
}