<template>
  <div class="card" style="max-width: 800px;">
    <img src="@/assets/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="card-header">
      <h1>Script Executions</h1>
      <p>View recent executions and their results</p>
    </div>
    <div class="card-body">
      <div v-if="executions.length === 0" class="text-center py-4">
        <p>No executions found</p>
      </div>
      <div v-else class="execution-list">
        <div 
          v-for="execution in executions" 
          :key="execution.id"
          class="execution-card"
          @click="$emit('openModal', 'execution-detail', { execution })"
        >
          <div class="execution-header">
            <div class="execution-info">
              <span class="execution-message">
                {{ execution.status === 'SUCCESS' ? 'Successful execution' : 'Execution failed' }}
              </span>
              <span class="execution-time">
                {{ new Date(execution.startTime).toLocaleString() }} · {{ execution.executionTimeMs }}ms
              </span>
            </div>
            <div class="execution-indicators">
              <span :class="['execution-type', execution.status.toLowerCase()]">
                {{ execution.status }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListExecutionsModal',
  props: {
    executions: Array
  },
  emits: ['openModal']
}
</script>

<style scoped>
.execution-list {
  display: flex;
  flex-direction: column;
  gap: var(--space-md);
  padding: var(--space-sm);
}

.execution-card {
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
  padding: var(--space-md);
  background: var(--background-primary);
  border: 1px solid var(--border-primary);
  border-radius: 2px;
  cursor: pointer;
  transition: var(--transition-base);
}

.execution-card:hover {
  background: var(--background-secondary);
  border-color: var(--primary-400);
}

.execution-card:active {
  transform: scale(0.995);
}

.execution-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--space-md);
}

.execution-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
}

.execution-message {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

.execution-time {
  color: var(--text-tertiary);
  font-size: var(--font-size-xs);
}

.execution-indicators {
  display: flex;
  gap: var(--space-xs);
  align-items: center;
}

.execution-type {
  font-size: var(--font-size-xs);
  padding: var(--space-xs) var(--space-sm);
  background: var(--background-secondary);
  color: var(--text-secondary);
  border-radius: 2px;
  text-transform: lowercase;
}

.execution-type.success {
  background: var(--success-700);
  color: var(--success-100);
}

.execution-type.error {
  background: var(--error-700);
  color: var(--error-100);
}
</style>